import { Grid, IconButton, Typography } from "@material-ui/core"
import React from "react"
import { colors } from "../../colors"
import card1 from "./../../assets/images//Vision/CardV1.svg"
import card2 from "./../../assets/images//Vision/CardV2.svg"
import card3 from "./../../assets/images//Vision/CardV3.svg"
import card4 from "./../../assets/images//Vision/CardV4.svg"
import card5 from "./../../assets/images//Vision/CardV5.svg"
import groupPic from "./../../assets/images/Vision/WhatsApp Image 2022-05-19 at 9.48.44 AM.png"
import f1 from "./../../assets/images/Vision/Dhruv.svg"
import f2 from "./../../assets/images/Vision/Devansh.svg"
import f3 from "./../../assets/images/Vision/Aryan.svg"
import f4 from "./../../assets/images/Vision/Varun.svg"
import LinkedIn from "./../../assets/images/Footer/LinkedIn.png"

import { Helmet } from "react-helmet"

import ellipseImg0 from "../../assets/images/Vision/Ellipse 20 (1).png"
import ellipseImg1 from "../../assets/images/Vision/Ellipse 20.png"
import ellipseImg2 from "../../assets/images/Vision/Ellipse 23.png"
import ellipseImg4 from "../../assets/images/Vision/Ellipse 19.png"



const Ellipse0 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "100px", right: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg0} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse1 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "400px", left: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg1} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse2 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "1200px", left: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg2} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse3 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "800px", right: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg4} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

function Vision({ isMobile }) {
  const styles = {
    mobileHeaderImage: {
      objectFit: "contain",
      width: "275px",
      margin: "2rem 0",
      borderRadius: "4px",
    },
    headerImage: {
      objectFit: "contain",
      width: "600px",
      margin: "2rem 0",
      borderRadius: "12px",
      marginBottom: "1rem",
    },
    foundersImage: {
      objectFit: "contain",
      width: "200px",
      margin: "1rem",
    },
    mobileFoundersImage: {
      objectFit: "contain",
      width: "140px",
      margin: "1rem",
    },
  }
  const founders = [
    // {
    //   img: f1,
    //   name: "Dhruv Upadhyay",
    //   position: "COO",
    //   linkedIn: "https://www.linkedin.com/in/intmanear/",
    // },
    {
      img: f2,
      name: "Devansh Swaroop",
      position: "CEO",
      linkedIn: "https://www.linkedin.com/in/devansh-swarup-85436554/",
    },
    {
      img: f3,
      name: "Aryan Pandey",
      position: "CPO",
      linkedIn: "https://www.linkedin.com/in/aryan-pandey/",
    },
    {
      img: f4,
      name: "Varun Goel",
      position: "CTO",
      linkedIn: "https://www.linkedin.com/in/varun-goel-132988192/",
    },
  ]
  const cardArray = [
    { img: card1, text: "Obsession to details", background: "#e5e8ff" },
    { img: card2, text: "Curiousity", background: "#d9ffc7" },
    { img: card3, text: "Bias for Action", background: "#ffd9cd" },
    { img: card4, text: "Ownership", background: "#ffe8a1" },
    { img: card5, text: "Altruism", background: "#f4befc" },
  ]
  return (
    <React.Fragment>

          {!isMobile ? <><Ellipse0 />
        <Ellipse1 />
        <Ellipse2 /> 
        <Ellipse3 />
        </>: null} 
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        data-aos="fade-up"
        style={{position: "relative"}}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {" "}
            CreatorPay - Use our Creators as Resellers and convert views to purchases !
          </title>
        </Helmet>

        <Grid item xs={12} style={{ textAlign: isMobile ? "left" : "center", marginTop: "-8rem" }}>
          <Typography
            variant="h1"
            // style={{
            //   // textDecorationLine: "underline",
            //   // textDecorationColor: colors.primary,
            // }}
          >
            Our Vision. Our Mission
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Typography style={{ marginTop: isMobile ? "-1.5rem" : "2rem", textAlign: isMobile ? "left" : "center" }} align="center">
            At Paperplane we’re building CreatorPay for transparent and cohesive integration between brands and creators on their favorite platforms!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: isMobile ? "center" : "center", margin: "1rem 0" }}>
          <img
            src={groupPic}
            style={isMobile ? styles.mobileHeaderImage : styles.headerImage}
            alt="grouoSvg"
          ></img>
        </Grid>
        <Grid item xs={12} style={{ textAlign: isMobile ? "left" : "center", marginTop: "2rem" }}>
          <Typography variant="h1" style={{ fontWeight: "700" }}>
            Our Core Values
          </Typography>
          <Typography variant="h5" style={{marginTop: "1rem"}}>
            The principles that guide our company strategy and our individual
            actions.
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "1rem" }}
        >
          {cardArray.map((ele) => (
            <div
              style={{
                width: isMobile ? "100px" : "110px",
                height: "120px",
                margin: "1rem",
                justifyContent: "space-between",
                background: "#262338",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <img
                src={ele.img}
                alt="card"
                style={{ objectFit: "100%", width: "30px" }}
              ></img>
              <Typography variant="body1">{ele.text}</Typography>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "3rem" }}>
          <Typography variant="h1" align="center">
            Meet the team
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "1rem" }}
        >
          {founders.map((ele) => (
            <div
              style={{
                width: "220px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                height: isMobile ? "250px" : "350px",
                margin: "0.5rem",
                background: "#262338",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <img
                style={
                  isMobile ? styles.mobileFoundersImage : styles.foundersImage
                }
                alt="founder"
                src={ele.img}
              ></img>
              <Typography variant="h5">{ele.name}</Typography>
              <Typography variant="h6" color="primary">
                {ele.position}
              </Typography>
              <div style={{}}>
                <IconButton
                  color="primary"
                  onClick={() => window.open(ele.linkedIn)}
                >
                  <img
                    src={LinkedIn}
                    alt={ele.to}
                    style={{ color: colors.primary, width: "32px" }}
                  ></img>
                </IconButton>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Vision
