import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import economic from "./../../assets/images/AsSeenOn/Group.svg";
import eIndia from "./../../assets/images/AsSeenOn/download 1.svg";
import yourStory from "./../../assets/images/AsSeenOn/Layer 1.svg";
import { ArrowForward } from "@material-ui/icons";
import GenericDialog from "../Common/GenericDialog";
function AsSeenOn({ isMobile }) {

  const [open, setOpen] = React.useState(false);
  const [redirectTo, setRedirectTo] = useState('')

  const style = {
    img: {
      width: "200px",
      height: isMobile ? "125px" : "150px",
      objectFit: "contain",
      cursor: "pointer",
    },
  };
  const array = [
    {
      img: yourStory,
      link: "https://yourstory.com/2022/04/bengaluru-healthtech-startup-paperplane-digital-clinics-whatsapp/amp",
    },
    {
      img: eIndia,
      link: "https://economictimes.indiatimes.com/tech/funding/digital-clinic-paperplane-raises-rs-3-crore-from-cornerstone-venture-partners-others/articleshow/90519313.cms",
    },
    { img: economic, link: "https://www.entrepreneur.com/article/423344" },
  ];
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      style={{
        textAlign: "center",
        margin: isMobile ? "3rem 0" : "5rem 0 0 0",
      }}
    >
      <Grid item style={{display: "flex", alignItems: "center"}}>
        <Typography variant="h2" color="initial" style={{opacity: "0.7", fontWeight: "bold", letterSpacing: "1px"}} align="center">
          As seen on 
        </Typography>
        <ArrowForward style={{marginLeft: "10px", color: "white", opacity: "0.7"}} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{
          margin: "1.5rem 0",
          //   padding: "1rem",
          //   background: "#FFFFFF",
          //   boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          //   borderRadius: "18px",
        }}
      >
        {array.map((ele) => (
          <Grid
            item
            // xs={12}
            style={{
              background: "rgba(38, 35, 56, 0.8)",
              padding: isMobile ? "2.5rem" : "2rem",
              margin: "2rem",
              borderRadius: "20px",
              position: "relative",
            }}
            className="logo"
          >
            <img
              src={ele.img}
              onClick={() => {
                setOpen(true);
                setRedirectTo(ele?.link)
              }}
              style={style.img}
              alt={ele?.link}
            />
            <IconButton
              style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
              color="primary"
              size="small"
              onClick={() => {
                setOpen(true);
                setRedirectTo(ele?.link)
              }}
            >
              <i class="fa-solid fa-arrow-up-right-from-square"></i>{" "}
            </IconButton>
          </Grid>
        ))}
      </Grid>
      <GenericDialog open={open} setOpen={setOpen} redirectTo={redirectTo} content={
        <React.Fragment>
          We started out by building <span style={{fontWeight: "bold"}}>"Storefronts"</span> for doctors and hospitals, our success in the healthcare space led to 
          interest from other sectors where brands wanted to sell products via our channel.<br /><br />

          Hence, we teamed-up with a suite of top social influencers and repurposed our technology for creator enabled e-commerce via CreatorPay.<br /><br />

          Here's a press article on the forementioned Healthcare product from us, <span style={{fontWeight: "bold"}}>Paper<span style={{color: "#4D63DD"}}>plane</span></span>
        </React.Fragment>
      } />
    </Grid>
  );
}

export default AsSeenOn;