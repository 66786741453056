import { Button, colors, Grid, Typography } from "@material-ui/core"
import mixpanel from "mixpanel-browser"
import React from "react"
import { Helmet } from "react-helmet"
import { NavLink } from "react-router-dom"
import fallbackImage from "./../../assets/images/Hero/hero-image.svg"
function Blogs({ blogList }) {
  return (
    <Grid container item spacing={3} justifyContent="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          CreatorPay - Use our Creators as Resellers and convert views to purchases !
        </title>
      </Helmet>
      <Grid item xs={12} style={{ textAlign: "center", margin: "2rem 0" }}>
        <Typography variant="h1">Blogs</Typography>
        <Typography variant="h3">
          Grow your medical practice with guides & articles from the best in
          business{" "}
        </Typography>
      </Grid>
      {blogList.map((ele) => (
        <Grid item container xs={12} md={3} sm={4} style={{ padding: "1rem" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            style={{
              backgroundColor: "#EFF1F7",
              padding: "2rem",
              borderRadius: "1rem",
            }}
          >
            <Grid item xs={12}>
              <img
                src={ele.blogImage ? ele.blogImage : fallbackImage}
                alt={ele.blogTitle}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ color: "#112A46" }}>
                {ele.blogTitle}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              item
              style={{ marginTop: "2rem", textAlign: "center" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                href={`/blogs/${ele.route}`}
                style={{ fontWeight: "lighter" }}
                onClick={() => {
                  mixpanel.track(`${ele.blogTitle} Clicked`, { from: "blogs" })
                }}
              >
                Read More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ))}{" "}
    </Grid>
  )
}

export default Blogs
