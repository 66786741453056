import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import axios from "axios"
import mixpanel from "mixpanel-browser"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import GenericTextfield from "../Common/GenericTextfield"
import StartButton from "../Common/StartButton"
import enterpriseImage from "./../../assets/images/Pricing/Group 36918.png"
import "../Common/Switch/index.css";


import { colors } from "../../colors"
import { ArrowForward, Close } from "@material-ui/icons"
function EnterpriseForm({ isMobile, open, setOpen }) {
  const styles = {
    number: {
      background: colors.purpleGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    text: {
      fontSize: "12px",
    },
  }
  const [state, setState] = useState({})
  const enterpriseAdvantages = [
    { number: "25+", text: "Businesses trust Paperplane Clinic" },
    { number: "50%", text: "Increase OPD occupancy by 50%" },
    { number: "40%", text: "Increase patient retention by 40%" },
  ]
  
  const creatorInputFields = [
    {
      placeholder: "Name",
      primaryKey: "name",
      md: 8,
      nextLine: true
    },
    {
      placeholder: "Email Address",
      primaryKey: "email",
      nextLine: true
    },
    {
      placeholder: "Phone No",
      primaryKey: "phoneNo",
      md: 6,
      nextLine: false
    },
    {
      placeholder: "Instagram Handle",
      primaryKey: "instaHandle",
      md: 6,
      nextLine: true
    },
    {
      placeholder: "How many times do you post per month?",
      primaryKey: "postPerMonth",
      nextLine: true
    },
    {
      placeholder: "How many followers do you currently have?",
      primaryKey: "followerNo",
      nextLine: true
    },
    {
      placeholder: "What kind of content category apply to you?",
      primaryKey: "contentCategory",
      nextLine: true
    },
    {
      placeholder: "Any other socials / profiles ?",
      primaryKey: "anyOther",
      nextLine: true
    },
  ]

  const brandInputFields = [
    {
      placeholder: "Brand Name",
      primaryKey: "name",
      md: 8,
      nextLine: true
    },
    {
      placeholder: "Brand Representative Name",
      primaryKey: "brandRepName",
      nextLine: true
    },
    {
      placeholder: "Phone No",
      primaryKey: "phoneNo",
      md: 6,
      nextLine: false
    },
    {
      placeholder: "Instagram Handle",
      primaryKey: "instaHandle",
      md: 6,
      nextLine: true
    },
    {
      placeholder: "Brand Representative Email",
      primaryKey: "brandRepEmail",
      nextLine: true
    },
    {
      placeholder: "What products does your brand offer?",
      primaryKey: "productOffer",
      nextLine: true
    },
    {
      placeholder: "What is your brand's monthly revenue?",
      primaryKey: "monthlyRevenue",
      nextLine: true
    },
    {
      placeholder: "How much is your approximate Monthly Performance Marketing expenditure?",
      primaryKey: "expenditure",
      nextLine: true,
      rows: 2
    },
    {
      placeholder: "Where do you sell your products (eg: Website, Youtube ads, etc)?",
      primaryKey: "salesChannel",
      nextLine: true
    },
  ]

  const [inputFields, setInputFields] = React.useState([...brandInputFields])

  const [loader, setLoader] = useState(false)

  const handleSubmit = (state) => {
    let flag = 1
    for (const field of inputFields) {
      if (field.validate && field.validate(state[field.primaryKey])) continue
      else if (!field.optional && !state[field.primaryKey]) {
        toast.error(field?.placeholder)
        flag = 0
        break
      }
    }
    if (flag) {
      axios
        .post(
          `https://pplane-api-prod.paperplane.health/api/v1/doctor/enterprise-data`,
          state
        )
        .then((res) => {
          if (res.data.success) {
            mixpanel.track("Enterprise Request Submitted", { ...state })
            setOpen(false)
            setState({})
            toast.success("We'll reach out to you within 24 hours.")
          } else {
            toast.error("Some error occured")
          }
        })
        .catch((err) => toast.error("Some error occured"))
    }
  }

  useEffect(() => {
    setChecked(false)
    setInputFields([...brandInputFields])
    if(open){
      setStepper(0)
    }
  }, [String(open)])

  const [stepper, setStepper] = useState(0)

  const [checked, setChecked] = useState(false)

  const getSteps = () => {
    switch(stepper) {
      case 0: {
        return(
          <React.Fragment>
            <Grid container item xs={12} justifyContent="center" spacing={2} style={{padding: "20px"}}>
              <Grid item>
                <Typography variant={isMobile ? "h3" : 'h1'} style={{letterSpacing: "2px"}}>
                  Lets get connected
                </Typography>
              </Grid>
              {/* <Grid item xs={12}></Grid> */}
              <Grid item xs={12}></Grid>
              <Grid item>
                <Typography style={{opacity: "0.6", textAlign: isMobile ? 'center' : null}}>
                  Please let us get to know you better so we can get you started
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Grid item>
                  <Typography variant="h6">
                    Who are you contacting as?
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item>
                <div className="switch-button">
                  <input
                    className="switch-button-checkbox"
                    type="checkbox"
                    value={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked)
                      if(e?.target?.checked) {
                        setInputFields([...creatorInputFields])
                      } else {
                        setInputFields([...brandInputFields])
                      }
                    }}
                  ></input>
                  <label className="switch-button-label" htmlFor="switch">
                    <span className="switch-button-label-span"> Brands </span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item style={{marginTop: "40px"}}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "15px",
                    background: "#A996FF",
                    color: "#4700AB",
                    fontSize: "14px"
                  }}
                  onClick={() => setStepper(1)}
                  endIcon={<ArrowForward />}
                >
                  Next Step
                </Button>
              </Grid>
            </Grid>

          </React.Fragment>
        )
      }

      case 1: {
        return (
          <React.Fragment>
            <Grid container item xs={12} justifyContent="center" spacing={2} style={{padding: isMobile ? "10px" : "20px"}}>
              <Grid item xs={12} md={7}>
                <Grid container spacing={1}>
                  {!isMobile ? <Grid item xs={12} md={4}>
                    <div style={{
                      borderRadius: "45px",
                      background: "#00D32A",
                      color: "white",
                      fontSize: "14px",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center"
                    }}>
                      <Typography style={{paddingTop: "10%"}}>
                        {checked ? "Creator" : "Brand"}
                      </Typography>
                    </div>
                  </Grid> : null}
                  
                  {inputFields?.map((val, idx) => (
                    <React.Fragment key={idx}>
                      <Grid item xs={12} md={val?.md ?? 12}>
                          <CustomTextField
                          multiline
                          rows={val?.rows ?? 1}
                            placeholder={val?.placeholder}
                          />
                        </Grid>
                      {val?.nextLine || isMobile ? <Grid item xs={12}></Grid> : null}
                    </React.Fragment>
                  ))}

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "15px",
                        background: "#A996FF",
                        color: "#4700AB",
                        fontSize: "14px"
                      }}
                      onClick={() => {
                        setStepper(2)
                      }}
                      endIcon={<ArrowForward />}
                      fullWidth
                    >
                      {checked ? "Complete" : "Sign Up"}
                    </Button>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }

      case 2: {
        return (
          <React.Fragment>
            <Grid container item xs={12} justifyContent="center" spacing={2} style={{padding: "20px", paddingTop: "50px"}}>
              <Grid item>
                <Typography variant={isMobile ? "h4" : 'h1'} style={{letterSpacing: "2px", textAlign: "center",}}>
                  Thanks for Contacting Us
                </Typography>
              </Grid>
              {/* <Grid item xs={12}></Grid> */}
              {/* <Grid item xs={12}></Grid>
              <Grid item>
                <Typography style={{opacity: "0.6"}}>
                  Please let us get to know you better so we can get you started
                </Typography>
              </Grid> */}
              <Grid item xs={12} container justifyContent="center">
                <Grid item>
                  <Typography variant="h6">
                    We'll get back to you shortly!
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              {/* <Grid item>
                <div className="switch-button">
                  <input
                    className="switch-button-checkbox"
                    type="checkbox"
                    value={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked)
                      if(e?.target?.checked) {
                        setInputFields([...creatorInputFields])
                      } else {
                        setInputFields([...brandInputFields])
                      }
                    }}
                  ></input>
                  <label className="switch-button-label" htmlFor="switch">
                    <span className="switch-button-label-span"> Brands </span>
                  </label>
                </div>
              </Grid> */}
              <Grid item xs={12}></Grid>
              {/* <Grid item style={{marginTop: "40px"}}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "15px",
                    background: "#A996FF",
                    color: "#4700AB",
                    fontSize: "14px"
                  }}
                  onClick={() => setStepper(1)}
                  endIcon={<ArrowForward />}
                >
                  Next Step
                </Button>
              </Grid> */}
            </Grid>

          </React.Fragment>
        )
      }

      default: {
        return null
      }
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md" PaperProps={{style: {background: "transparent"}}}>
      {/* <DialogTitle title="hello" l>Paperplane Enterprise</DialogTitle> */}
      <DialogContent
        style={{
          background: "#262338",
          borderRadius: "20px"
        }}
        
      >
        <Grid
          container
          xs={12}
          justifyContent="center"
          style={{ alignItems: "center", position: "relative" }}
          // direction={!isMobile ? "initial" : "column-reverse"}
        >
          <IconButton style={{position: "absolute", top: isMobile ? "-20px" : 0, right: isMobile ? "-20px" : 0, color: "white"}} size={isMobile ? "small" : null} onClick={() => {
            setOpen(false);
            setTimeout(() => {
              setStepper(0)
            }, 500)
            }}>
            <Close />
          </IconButton>

          {getSteps()}

          {/* <Grid
            container
            md={6}
            xs={12}
            justifyContent="center"
            spacing={2}
            alignItems="center"
            style={{
              padding: "2rem",
              // background:
              //   "linear-gradient(110.86deg, rgba(77, 99, 221, 0.2) 31.36%, rgba(34, 161, 245, 0.2) 100%)",
            }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h3">
                Book a demo for <br />
                <strong>Paperplane Enterprise</strong>
              </Typography>
            </Grid>
            {/* </Hidden> 
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src={enterpriseImage}
                style={{ width: isMobile ? "200px" : "300px" }}
                alt="Paperplane Enterprise"
              ></img>
            </Grid>
            <Grid container item xs={12} justifyContent="space-evenly">
              {enterpriseAdvantages.map((ele) => (
                <Grid item style={{ padding: "1rem" }} md={4}>
                  <Grid
                    item
                    style={{
                      background: "aliceblue",
                      padding: "1rem",
                      borderRadius: "18px",
                    }}
                  >
                    <Typography
                      variant="h1"
                      align="center"
                      style={styles.number}
                    >
                      {ele.number}
                    </Typography>
                    <Typography
                      style={{ marginTop: "1rem", ...styles.text }}
                      variant="body1"
                      align="center"
                    >
                      {ele?.text}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={6}
            item
            alignItems="flex-end"
            spacing={2}
            style={{
              padding: isMobile ? "1rem" : "2rem",
              borderRadius: "18px",
              background: "white",
            }}
          >
            {/* <Hidden mdUp>
              <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                <Typography variant="h3" align="center">
                  Book a demo for <br />
                  <strong>Paperplane Enterprise</strong>
                </Typography>
              </Grid>
            </Hidden> 
            {inputFields.map((field) => (
              <GenericTextfield
                {...field}
                state={state}
                setState={setState}
              ></GenericTextfield>
            ))}{" "}
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2rem",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <StartButton
                label="Submit"
                onClick={() => handleSubmit(state)}
              ></StartButton>
            </Grid>
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default EnterpriseForm




const useStyles = makeStyles((theme) => ({
  textField: {
    // color: '#AAB7B8', // Change the color of the placeholder text
    // opacity: 1,
    // // fontWeight: "bold",
    // borderColor: "#AAB7B8 !important"
    borderColor: "transparent",
    paddingBottom: "10px",

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired hover color
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired focused color
    },
  },
}))


const CustomTextField = ({...rest}) => {

  const classes = useStyles();

  return (
    <TextField
      inputProps={{ maxLength: "10", style: {"background": "#4E4B66", color: "white", paddingBottom: "10px", borderColor: "transparent"} }}
      variant="outlined"
      fullWidth
      type="text"
      InputProps={{
        style: { borderRadius: "15px", borderColor: "transparent", paddingBottom: "10px", "background": "#4E4B66", root: {
          '& $notchedOutline': {
            borderColor: 'transparent', // Change this to the desired color
          },
          '&:hover $notchedOutline': {
            borderColor: 'transparent', // Change this to the desired hover color
          },
          '&$focused $notchedOutline': {
            borderColor: 'transparent', // Change this to the desired focused color
          },
        }, },
        classes: {
          root: classes.textField,
          notchedOutline: classes.textField,
        }
      }}
      {...rest}
    ></TextField>
  )
}