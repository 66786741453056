import { Button } from "@material-ui/core";
import mixpanel from "mixpanel-browser";
import React from "react";
function GetStarted({ label = "Get Started", style = {}, to = "", ...rest }) {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ ...style }}
      onClick={() => {
        mixpanel.track(`${label} Clicked`);
        if (rest.onClick) rest.onClick();
        else window.open(to);
      }}
      {...rest}
    >
      {label}
    </Button>
  );
}

export default GetStarted;
