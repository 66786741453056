import { Grid, Typography } from "@material-ui/core"
import React from "react"

function Refund() {
  return (
    <Grid container data-aos="fade-up">
      <Grid item xs={12}>
        <Typography align="center" variant="h1">
          Cancellation Policy
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <p>
            Our focus is complete customer satisfaction. In the event, if you
            are displeased with the services provided, we will refund back the
            money, provided the reasons are genuine and proved after
            investigation. Please read the fine prints of each deal before
            buying it, it provides all the details about the services or the
            product you purchase.
          </p>

          <p>
            In case of dissatisfaction from our services, clients have the
            liberty to cancel their projects and request a refund from us. Our
            Policy for the cancellation and refund will be as follows:
          </p>
          <p style={{ fontWeight: "600", fontSize: "22px" }}>
            Cancellation Policy :
          </p>
          <p>For Cancellations please contact the us via contact us link. </p>
          <p>
            Requests received later than 30 business days prior to the end of
            the current service period will be treated as cancellation of
            services for the next service period.
          </p>
          {/* 
          <p style={{ fontWeight: "600", fontSize: "22px" }}>Refund Policy :</p>

          <p>
            We will try our best to create the suitable design concepts for our
            clients.
          </p>

          <p>
          </p>

          <p>
           </p> */}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Refund
