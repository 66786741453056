import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { BusinessOutlined, Email, EmailOutlined, Phone, PhoneOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { colors } from "../../colors";
import ContactInput from "../Common/ContactInput";

function ContactUs({ isMobile = false }) {
  return (
    <Grid
      id="contact-us"
      container
      justifyContent="center"
      style={{ marginTop: "2rem" }}
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
    >
      <Grid item xs={12} md={10}>
        <div
          style={{
            padding: "2rem",
            background: "rgba(38, 35, 56, 0.8)",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "18px",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent={isMobile ? "center" : "center"}
          >
            
            <Grid
              container
              justifyContent={isMobile ? "center" : "flex-end"}
              item
              md={7}
              xs={12}
            >
              <ContactInput isMobile={isMobile}></ContactInput>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              container
              direction="row"
              alignItems={isMobile ? "center" : "flex-start"}
              style={{marginTop: isMobile ? "15px" : "65px"}}
            >
              <Grid item xs={12} container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "inherit",

                  margin: "0.25rem 0",
                }}
              >
                <Grid item style={{display: "flex", alignItems: "center"}}>
                  <EmailOutlined
                  
                    fontSize="small"
                    style={{ paddingRight: "0.5rem", color: "white" }}
                  />

                  <a
                    href="mailto:support@paperplanetech.co"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography style={{opacity: "0.7"}} variant="h6">
                      support@paperplanetech.co
                    </Typography>{" "}
                  </a>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                  <PhoneOutlined
                    fontSize="small"
                    style={{ paddingRight: "0.5rem", color: 'white' }}
                  />

                  <a href="tel:+91 6387662004" style={{ textDecoration: "none" }}>
                    <Typography style={{opacity: "0.7"}} variant="h6">
                      +91 6387662004</Typography>{" "}
                  </a>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "inherit",
                  margin: "0.25rem 0",
                }}
              >
                <BusinessOutlined
                  fontSize="small"
                  style={{ paddingRight: "0.5rem", color: "white" }}
                />

                <a href="tel:+91 6387662004" style={{ textDecoration: "none" }}>
                  <Typography style={{opacity: "0.7"}} variant="h6">
                    XYZ Street , ABC road
                    Bangalore 302934
                    India
                  </Typography>{" "}
                </a>
              </Grid> */}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
