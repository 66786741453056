import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    Hidden,
    IconButton,
    Typography,
  } from "@material-ui/core"
  import React, { useEffect, useState } from "react"
  import "../../Common/Switch/index.css";
  
  
  import { ArrowForward, Close } from "@material-ui/icons"
  function GenericDialog({ isMobile, open, setOpen, content, redirectTo }) {
   
    
  
    return (
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm" PaperProps={{style: {background: "transparent"}}}>
        {/* <DialogTitle title="hello" l>Paperplane Enterprise</DialogTitle> */}
        <DialogContent
          style={{
            background: "#262338",
            borderRadius: "20px"
          }}
          
        >
          <Grid
            container
            xs={12}
            justifyContent="center"
            style={{ alignItems: "center", position: "relative" }}
            // direction={!isMobile ? "initial" : "column-reverse"}
            spacing={2}
          >
            <IconButton style={{position: "absolute", top: isMobile ? "-20px" : 0, right: isMobile ? "-20px" : 0, color: "white"}} size={isMobile ? "small" : null} onClick={() => {
              setOpen(false);
              }}>
              <Close />
            </IconButton>
  
            <Grid item xs={12}></Grid>

            <Grid item xs={12} container justifyContent="center">
                <Grid item style={{width: "80%"}}>
                    <Typography style={{textAlign: "center"}}>
                        {content}
                    </Typography>
                </Grid>
            </Grid>
  
            <Grid item xs={12}></Grid>

            <Grid item>
                <Button
                    variant="contained"
                    size="small"
                    style={{
                    borderRadius: "15px",
                    background: "#A996FF",
                    color: "#4700AB",
                    fontSize: "14px",
                    padding: "5px 15px"
                    }}
                    onClick={() => {
                        window.open(redirectTo)
                    }}
                    endIcon={<ArrowForward />}
                    fullWidth
                >
                    Visit Page
                </Button>
            </Grid>

            <Grid item xs={12}></Grid>
           
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
  
  export default GenericDialog
  