import { Button, Grid, Typography } from "@material-ui/core"
import React from "react"
// import abhaHeroImg from "./../../assets/images/EnterpriseBody/Group 36839.png"

import access from "./../../assets/images/Abdm/access.png"
import id from "./../../assets/images/Abdm/ID.png"
import signUp from "./../../assets/images/Abdm/sign up.png"
import unified from "./../../assets/images/Abdm/UNIFIED.png"
import bgHero from "./../../assets/images/Abdm/bgHero.png"
import abhaCard from "./../../assets/images/Abdm/abhaCard.png"
import bnplCard from "./../../assets/images/bnpl/final card design.svg"
import blueWhatsapp from "./../../assets/images/bnpl/blue_whatsapp.png"
import flRupee from "./../../assets/images/bnpl/fl_rupee.png"
import webPage from "./../../assets/images/bnpl/web-page.png"

import logo from "./../../assets/images/Abdm/abdmLogo.png"
import abhaFeatures from "./../../assets/images/Abdm/abhaF.png"
import FAQ from "../../Components/FAQ"
import mixpanel from "mixpanel-browser"
export const BnplHeroSection = ({ isMobile }) => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      style={{
        padding: "1rem",
        borderRadius: "28px",
        backgroundImage: `url(${bgHero})`,
      }}
    //   spacing={1}
      direction={isMobile ? "row-reverse" : "inherit"}
      alignItems="center"
    >   
    <Grid item>
        <Typography variant="h1" style={{fontSize: isMobile ? "1.4rem" : "2.4rem"}}>Introducing</Typography>
    </Grid>
        <Grid item xs={12}></Grid>
        <Grid item 
        style={{ marginTop: "10px", marginBottom: "5px" }}
            >
        <Typography variant="h1" style={{fontSize: isMobile ? "1.6rem" : "2.8rem", 
        background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    }}>Buy Now, Pay Later</Typography>
    </Grid>
    <Grid item xs={12}></Grid>
    <Grid item>
        <Typography variant={isMobile ? "h5":"h3"} style={{justifyContent: 'center', display: "flex"}}>Instant Credit for your health on a click!</Typography>
    </Grid>
        <Grid item xs={12}></Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ marginTop: "10px"}}
      >
        <img
          src={bnplCard}
          style={{
            objectFit: "contain",
            width: "100%",
          }}
          alt="ABHA from Paperplane"
        />
      </Grid> 

      {/* {<Grid
        item
        xs={12}
        md={9}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 11px 32px rgba(0, 0, 0, 0.25)",
          padding: "1rem",
          // height: "100%",
          borderRadius: "27px",
        }}
      >
        <Grid item md={8} xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h1">
            Creating India's Digital Health Ecosystem
          </Typography>
          <Typography variant="h4" style={{ fontWeight: "500" }}>
            ABHA - Ayushman Bharat Health Account
          </Typography>
          <Typography variant="h5" style={{ fontWeight: "300" }}>
            Get control of your health data and documents using WhatsApp
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={8}
          style={{
            marginTop: "2rem",
            textAlign: isMobile ? "center" : "left",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            md={5}
            xs={12}
            style={{ marginBottom: isMobile ? "1rem" : "0" }}
          >
            <img
              src={logo}
              style={{
                objectFit: "contain",
                width: isMobile ? "125px" : "200px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={7} container>
            <Grid item xs={12}>
              <img
                src={abhaFeatures}
                style={{
                  objectFit: "contain",
                  width: isMobile ? "300px" : "450px",
                }}
              />
            </Grid>
            <CreateAbhaButton />
          </Grid>
        </Grid>
      </Grid> } */}
    </Grid>
  )
}
const CreateAbhaButton = ({ label = "Create abha id now >", ...rest }) => {
  return (
    <Button
      style={{
        background: "#75BF63",
        color: "white",
        fontFamily: "Inter",
        marginTop: "1rem",
        padding: "0.5rem 1rem",
      }}
      onClick={() => {
        mixpanel.track("Create abha id now clicked")
        window.open("https://go.paperplane.health/abha")
      }}
      {...rest}
    >
      {label}
    </Button>
  )
}
function Bnpl({ isMobile }) {
  const features = [
    {
      title: "Under 1 Minute approval",
      image: id,
      description: "By putting your health ahead of paperwork and logistics",
    },
    {
      title: "Repayments via WhatsApp",
      image: blueWhatsapp,
      description:
        "Pay securely via whatsapp without downloading or using any application",
    },
    {
      title: "Flexible Payment Tenure",
      image: flRupee,
      description:
        "Now you decide when and how much to pay in installments",
    },
    {
      title: "No Third-party intervention",
      image: webPage,
      description:
        "Seamless transaction through Paperplane",
    },
  ]
  return (
    <Grid item container xs={12}>
      <BnplHeroSection isMobile={isMobile} />
      <Grid
        item
        xs={12}
        container
        style={{
          margin: "3rem 0",
          padding: "1rem",
          background: "#f3f3f3",
          borderRadius: "28px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" align="center" style={{ fontWeight: "700" }}>
            With Buy Now, Pay Later you get
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="baseline"
          style={{ marginTop: "2rem" }}
        >
          {features.map((ele) => (
            <Grid
              item
              xs={12}
              md={6}
              style={{
                // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                // borderRadius: "12px",
                padding: isMobile ? "1rem" : "1rem 6rem",
                textAlign: isMobile ? "center" : "center",
              }}
            >
              <img
                src={ele.image}
                style={{ width: "80px", height: "80px", objectFit: "contain" }}
              />

              <Typography
                variant="h5"
                style={{ marginTop: "1rem", fontWeight: "600" }}
              >
                {ele.title}
              </Typography>
              <Typography variant="h6" style={{ marginTop: "1rem" }}>
                {ele.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {/* <Grid item xs={12} style={{ textAlign: "center" }}>
          <CreateAbhaButton />
        </Grid> */}
      </Grid>
      {/* <FAQ
        title={"ABHA (Ayushman Bharat Health Account) Fundamentals"}
        questions={[
          {
            question: "Updating?",
            answer:
              "ABHA number is a 14 digit number that will uniquely identify you as a participant in India’s digital healthcare ecosystem. ABHA number will establish a strong and trustable identity for you that will be accepted by healthcare providers and payers across the country.our Ayushman Bharat Health Account (ABHA)        is a hassle-free method of accessing and sharing your health records digitally. It enables your interaction with participating healthcare providers and allows you to receive your digital lab reports,prescriptions, and diagnoses seamlessly from verified healthcare professionals and health service providers.",
          },
          {
            question: "Why you should create ABHA?",
            answer:
              "Using ABHA (earlier known as Health ID) is the first step towards creating safer and more efficient digital health records for you and your family. You can opt-in to create a digitally secure ABHA, which allows you to access and share your health data with your consent, with participating healthcare providers and payers. ",
          },
        ]}
      /> */}
    </Grid>
  )
}

export default Bnpl
