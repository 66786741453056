import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { colors } from "../../colors"
import Animation from "../Animation"
import StartButton from "../Common/StartButton"
import heroFrame from "./../../assets/gifs/hero_10.gif"
import heroVid from "./../../assets/animation/video/ig store vid.mp4"
import heroThumb from "./../../assets/images/Hero/hero_default_img.png"

import whatsappAnimation from "./../../assets/animation/94110-whatsapp-transparent.json"
import animationData from "../../assets/animation/gif-animation/hero.json"
import videoBackground from "../../assets/animation/video/backvideo_1920x1500.mp4"
import TextTransition, { presets } from 'react-text-transition';



import "./index.css"
const TEXTS = ['Creators', 'Influencers', 'Celebrities', 'Youtubers'];
const TEXTS1 = ['Resellers', "Affiliates", 'Curators', 'Affiliates'];
const TEXTS2 = ['views', 'impressions', 'clicks', 'audience'];
const TEXTS3 = ['sales', 'purchases', 'revenue', 'sales'];
const useStyles = makeStyles((theme) => ({
  imageContainer: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  parentGrid: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column-reverse",
      marginTop: "-8rem"
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "-10rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "-10rem",
      marginBottom: "2rem",
    },
  },
  countUpCard1: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },

    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  doctorDetailsSingleDoc: {
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "center",
      marginBottom: "2rem",
      marginTop: "2rem",

      textAlign: "center"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "8%"
    }
  },
}))


const VideoBackground = () => {
  return (
    <div style={{
      position: "absolute",
      top: "-8%", left: 0,
       width: "100%", height: "100%", zIndex: -1
    }}>
      <video autoPlay loop muted style={{objectFit: "cover"}}>
        {/* Provide the path to your video file */}
        <source src={videoBackground} type="video/mp4" />
        {/* Your browser does not support the video tag. */}
      </video>
    </div>
  );
};


function Hero({ isMobile, setOpen, isLScreen }) {

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };
  
  // const heroImg = "https://storage.googleapis.com/website-paperplane/hero111.gif"

  const classes = useStyles()
  const styles = {
    mobileHeaderImage: {
      objectFit: "contain",
      width: "100%",
      marginBottom: "1rem",
    },
    headerImage: {
      objectFit: "contain",
      width: "95%",
      marginBottom: "1rem",
    },
    headerImageDoubleDoc: {
      objectFit: "contain",
      width: "400px",
    },
    mobileHeaderImageDoubleDoc: {
      objectFit: "contain",
      width: "320px",
      marginBottom: "1rem",
    },
  }

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      4000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);


  return (
    <Grid container justifyContent="center" className={classes.parentGrid} spacing={1}>
      {!isMobile ? <VideoBackground /> : null}
      <Grid
        container
        item
        md={7}
        // data-aos-once={true}
        // data-aos={isMobile ? "fade" : "fade-right"}
        xs={12}
        spacing={2}
        alignItems="center"
        className={classes.doctorDetailsSingleDoc}
      >
        <Grid item style={{marginLeft: "1%"}}>
        <Typography variant={isMobile ? "h3" : "h1"} style={{ textAlign: isMobile ? "left" : null, fontWeight: "500", fontSize: isMobile ? "38px" : (isLScreen ? "60px" : "50px"), letterSpacing: "1px", lineHeight: isMobile ? "48px" : null, position: "relative"}}>
            Use our {" "}
          {isMobile ? <br/> :  null}
            
            <TextTransition inline delay={!index ? 0 : 2500} springConfig={presets.gentle}>
              <span style={{
                background: "linear-gradient(180deg, rgba(0, 255, 20, 0.7) 34.9%, rgba(255, 255, 255, 0) 108.12%)",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: "text",
                textFillColor: "transparent",
                fontWeight: "700",
              }}>
                {TEXTS[index % TEXTS.length]}
              </span>
              </TextTransition>{" "}
            
            {/* <div class="animated-text" style={{
              position: "absolute",
              left: "335px",
              top: 30
            }}>
              {TEXTS?.map(v => (<div class="line" style={{
                // background: "linear-gradient(180deg, rgba(0, 255, 20, 0.7) 34.9%, rgba(255, 255, 255, 0) 108.12%)",
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
                // backgroundClip: "text",
                // textFillColor: "transparent",
                fontWeight: "700",
              }}>{v}</div>))}
              </div> */}
          </Typography>
          <Typography variant="h1" style={{ textAlign: isMobile ? "left" : null, fontWeight: "500", fontSize: isMobile ? "38px" : (isLScreen ? "60px" : "50px"), letterSpacing: "1px", marginTop: isMobile ? "0px" : "10px", position: 'relative', lineHeight: isMobile ? "48px" : null }}>
          as {" "}
                          <TextTransition delay={!index ? 0 : 2500} inline springConfig={presets.gentle}>
                            <span style={{
                              background: "linear-gradient(180deg, rgba(0, 255, 20, 0.7) 34.9%, rgba(255, 255, 255, 0) 108.12%)",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: "text",
                textFillColor: "transparent",
                fontWeight: "700",

                              }}>
                            {TEXTS1[index % TEXTS1.length]}
                            </span>
                            </TextTransition>{" "}

         <span style={{
          position: !isMobile ? (!index ? "absolute" : null) : null,
          top: 0,
          left: "73.8%",
          textAlign: "left",
          width: !isMobile ? (!index ? "300px" : null) : null
         }}>
          {isMobile ? <br style={{height: "0px"}} /> : null}
          and convert
          </span>
          </Typography>
          <Typography variant="h1" style={{ textAlign: isMobile ? "left" : null, fontWeight: "500", fontSize: isMobile ? "38px" : (isLScreen ? "60px" : "50px"), letterSpacing: "1px", marginTop: isMobile ? "0px" : "10px", position: 'relative', lineHeight: isMobile ? "48px" : null }}>
         
              <TextTransition delay={!index ? 0 : 2500} inline springConfig={presets.gentle}>
                            <span style={{
                              background:"linear-gradient(180deg, #4D63DD 66.14%, rgba(77, 99, 221, 0) 109%)",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: "text",
                textFillColor: "transparent",
                fontWeight: "700",

                              }}>
                            {TEXTS2[index % TEXTS2.length]}
                            </span>
                            </TextTransition>{" "}
        <span style={{
          // position: !isMobile ? (!index ? "absolute" : null) : null,
          top: 0,
          left: "35.9%",
          textAlign: "left",
          width: !isMobile ? (!index ? "300px" : null) : null
         }}>
          {isMobile ? <br style={{height: "0px"}} /> : null}
               to {' '} 
              <TextTransition delay={!index ? 0 : 2500} inline springConfig={presets.gentle}>
                            <span style={{
                              background: "linear-gradient(180deg, #4D63DD 66.14%, rgba(77, 99, 221, 0) 109%)",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: "text",
                textFillColor: "transparent",
                fontWeight: "700",

                              }}>
                            {TEXTS3[index % TEXTS3.length]}
                            </span>
                            </TextTransition>
                            </span>
                            {" "}
            {/* <span style={{
              background: "linear-gradient(180deg, #4D63DD 66.14%, rgba(77, 99, 221, 0) 109%)",
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: "text",
              textFillColor: "transparent",
              fontWeight: "700"
            }}>
              purchases
            </span> ! */}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        md={5}
        xs={12}
        data-aos-once={true}
        data-aos={isMobile ? "slide-down" : "fade-left"}
        // data-aos={"slide-down"}
        style={{
          // alignItems: "center",
          // textAlign: "end",
          // justifyContent: "end",
          display: "grid",
          // flexDirection: "column",
          marginTop: "4rem",
          paddingLeft: isMobile ? null : (isLScreen ? "2%" : "3%"),
        }}
      >
        <img
          src={heroFrame}
          style={{
            gridArea: "1 / 1",
            width: "100%",
            height: "auto",
            zIndex: 3
          }}
          // style={isMobile ? styles.mobileHeaderImage : styles.headerImage}
        ></img>
        <video autoPlay playsInline loop muted style={{objectFit: "cover", gridArea: "1 / 1", width: "40%", height: "auto", marginLeft: "31.41%", marginTop: "4.6%", borderRadius: "30px"}}
          onLoadedData={handleVideoLoad}
        >
        <source src={heroVid} type="video/mp4" />
      </video> 
      {!isVideoLoaded ? <img
        src={heroThumb}
        style={{objectFit: "cover", gridArea: "1 / 1", width: "40%", height: "auto", marginLeft: "31.41%", marginTop: "5.5%", borderRadius: "30px", zIndex: 2}}
      /> : null}
          {/* <Animation animationData={animationData} height={isMobile ? "100%" : (isLScreen ? "100%" : "100%")} width={isMobile ? "100%" : (isLScreen ? "100%" : "100%")}/> */}
      </Grid>
    </Grid>
  )
}

export default Hero
