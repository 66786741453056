import { Divider, Grid, Hidden, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { colors } from "../../colors"
import StartButton from "../Common/StartButton"
import Animation from "../Animation"


import ellipseImg0 from "../../assets/images/Vision/Ellipse 20 (1).png"
import ellipseImg1 from "../../assets/images/Vision/Ellipse 20.png"
import ellipseImg2 from "../../assets/images/Vision/Ellipse 23.png"
import ellipseImg4 from "../../assets/images/Vision/Ellipse 19.png"
import ellipseImg5 from "../../assets/images/Vision/Ellipse 24.png"
import ellipseImg6 from "../../assets/images/Vision/Ellipse 29.png"
import ellipseImg7 from "../../assets/images/Vision/Ellipse 26.png"
import ellipseImg8 from "../../assets/images/Vision/Ellipse 27.png"
import ellipseImg9 from "../../assets/images/Vision/Ellipse 28.png"

import firstVid from "../../assets/animation/video/b1 illustration vid.mp4"
import firstVidThumbnail from "../../assets/images/Features/thumbfirst.jpeg"



const Ellipse0 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "1400px", right: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg0} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse1 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "1900px", left: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg1} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse2 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "2950px", left: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg2} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse3 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "2450px", right: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg4} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};


const Ellipse4 = () => {
  return (
    <div style={{
      position: "absolute",
      top: "3450px", right: 0,
       width: "40rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg5} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};



const Ellipse5 = ({top = "3650px"}) => {
  return (
    <div style={{
      position: "absolute",
      top: top, left: "30%",
       width: "50rem", height: "55rem", zIndex: -1
    }}>
        <img src={ellipseImg6} alt="Background" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};



function Body({
  isMobile = false,
  body = { features: [] },
  styles,
  buttonProps = { label: "Start Now", onClick: () => {} },
}) {


  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Grid container style={{ margin: "2rem 0" }}>
      {!isMobile ? <><Ellipse0 />
      <Ellipse1 />
      <Ellipse2 /> 
      <Ellipse3 /> 
      {body?.features?.length > 4 ? <Ellipse4 /> : null}
      <Ellipse5 top={body?.features?.length > 4 ? "4050px" : "3500px"} /></> : null}
      {/* <Grid
        container
        item
        xs={12}
        data-aos="fade-in"
        data-aos-once={true}
        data-aos-delay={400}
        justifyContent="center"
        spacing={3}
        alignItems="center"
        style={{ marginTop: "2rem" }}
      >
        <Grid item>
          <Typography
            variant="h5"
            style={{ fontWeight: "500", fontFamily: "Manrope" }}
          >
            <span style={{ fontWeight: "600" }}>Paperplane</span>
            <span
              style={{
                padding: "0.5rem",
                background: colors.gradient,
                color: "#fff",
                marginLeft: "0.5rem",
                borderRadius: "11px",
              }}
            >
              {body?.text}
            </span>
            <br />
            Right for {isMobile ? "👇" : "👉"}
          </Typography>
          <div style={{ textAlign: isMobile ? "center" : "left" }}>
            <StartButton
              {...buttonProps}
              style={{ margin: "2rem 0rem" }}
            ></StartButton>
          </div>
        </Grid>
        {body?.heading?.map((ele, idx) => (
          <div key={idx}>
            <div
              style={{
                width: isMobile ? "150px" : "200px",
                height: isMobile ? "180px" : "220px",
                margin: "1.6rem 0.4rem",
                background: "#fff",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.18)",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                alginItems: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(161, 176, 255, 0.5)",
                  textAlign: "center",
                  borderRadius: "18px 18px 0 0  ",
                  padding: "1rem",
                }}
              >
                <img
                  src={ele.img}
                  alt={ele.text}
                  style={{
                    objectFit: "contain",
                    width: isMobile ? "80px" : "120px",
                    height: isMobile ? "80px" : "120px",
                  }}
                ></img>
              </div>
              <div
                style={{
                  padding: "0.5rem",
                  height: "inherit",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{ fontWeight: "700" }}
                >
                  {ele.text}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </Grid> */}
      <Hidden mdUp>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Hidden>
      {body?.features?.map((ele, idx) => (
        <Grid
          container
          key={idx}
          spacing={2}
          alignItems="center"
          style={{ margin: "3rem 0" }}
        >
          {isMobile || idx % 2 !== 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "center", paddingRight: isMobile ? null : "15rem", display: ele?.isDouble ? "grid" : null }}
              data-aos={isMobile ? "fade-up" : "fade-right"}
              data-aos-once={true}
              data-aos-delay={isMobile ? 100 : 200}
            >
              {/* <Animation animationData={ele?.img} height={isMobile ? "100%" : (ele?.imgStyle ? ele?.imgStyle?.height : "100%")} width={isMobile ? "100%" : (ele?.imgStyle ? ele?.imgStyle?.width : "100%")}/> */}
              <img
                src={ele.img}
                style={
                  isMobile
                  ? {...styles.mobileHeaderImage, gridArea: ele?.isDouble ? "1 / 1" : null, height: "auto", zIndex: ele?.isDouble ? 3 : null, width: ele?.isDouble ? "100%" : "95%"}
                   : (ele.imgStyle
                    ? ele.imgStyle
                    : styles.headerImage)
                }
                alt={ele.heading}
              ></img>
              {ele?.isDouble && isMobile ? (
                <video autoPlay playsInline loop muted style={{objectFit: "cover", gridArea: "1 / 1", width: "43%", height: "auto", marginLeft: "29.5%", marginTop: "6.9%", borderRadius: "25px"}}
                  onLoadedData={handleVideoLoad}
                >
                  <source src={firstVid} type="video/mp4" />
                </video> 
              ) : null}
              {ele?.isDouble ? (
                !isVideoLoaded ? <img
                  src={firstVidThumbnail}
                  style={{objectFit: "cover", gridArea: "1 / 1", width: "42.25%", height: "auto", marginLeft: "30%", marginTop: "7.5%", borderRadius: "20px", zIndex: 5}}
                /> : null
              ) : null}
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            data-aos={
              isMobile ? "fade-up" : idx % 2 !== 0 ? "fade-left" : "fade-right"
            }
            md={6}
            data-aos-delay={isMobile ? 100 : 200}
            data-aos-once={true}
            style={{ textAlign: isMobile ? "center" : "left" }}
          >
            <Typography
              variant="h1"
              style={{ marginBottom: "2rem", fontWeight: "700", textAlign: isMobile ? "left" : null }}
            >
              {ele.heading}
            </Typography>
            <Grid item style={{ textAlign: "left" }}>
              <Typography variant="h5" color="textSecondary">
                {ele.content}
              </Typography>
            </Grid>
            {/* <StartButton {...buttonProps} style={{ marginTop: "2rem" }} /> */}
          </Grid>
          {!isMobile && idx % 2 === 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "center", paddingLeft: isMobile ? null: "5rem", display: ele?.isDouble ? "grid" : null}}
              data-aos="fade-left"
              data-aos-delay={isMobile ? 100 : 200}
              data-aos-once={true}
            >
              {/* <Animation animationData={ele?.img} height={isMobile ? "100%" : (ele?.imgStyle ? ele?.imgStyle?.height : "100%")} width={isMobile ? "100%" : (ele?.imgStyle ? ele?.imgStyle?.width : "100%")}/> */}
              <img
                src={ele.img}
                style={
                  isMobile
                  ? styles.mobileHeaderImage
                   : (ele.imgStyle
                    ? ele.imgStyle
                    : styles.headerImage)
                }
                alt={ele.heading}
              ></img>
              {ele?.isDouble ? (
                <video autoPlay playsInline loop muted style={{objectFit: "cover", gridArea: "1 / 1", width: "43%", height: "auto", marginLeft: "29.7%", marginTop: "6.5%", borderRadius: "30px"}}
                  onLoadedData={handleVideoLoad}
                >
                  <source src={firstVid} type="video/mp4" />
                </video> 
              ) : null}
              {ele?.isDouble ? (
                !isVideoLoaded ? <img
                  src={firstVidThumbnail}
                  style={{objectFit: "cover", gridArea: "1 / 1", width: "42.3%", height: "auto", marginLeft: "30%", marginTop: "7.7%", borderRadius: "30px", zIndex: 5}}
                /> : null
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      ))}
    </Grid>
  )
}

export default Body
