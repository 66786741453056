import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { colors } from "../../../colors";
import axios from "axios";
import { toast } from "react-toastify";
import { ArrowForward } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  textField: {
    // color: '#AAB7B8', // Change the color of the placeholder text
    // opacity: 1,
    // // fontWeight: "bold",
    // borderColor: "#AAB7B8 !important"
    borderColor: "transparent",
    paddingBottom: "10px",

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired hover color
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', // Change this to the desired focused color
    },
  },
}))


function ContactInput({ isMobile = false }) {
  const classes = useStyles();
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const regex = new RegExp("(^[0-9]+$|^$)");
  const submitPhone = () => {
    if (number.toString().length !== 10)
      toast.error("Phone number must be of 10 digits.");
    else {
      setLoading(true);
      axios
        .post(
          `https://pplane-api-prod.paperplane.health/api/v1/ai/send-email?email=${number}`
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNumber("");
          toast.success("We'll get back to you shortly!");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <Grid item xs={12} container justifyContent="center" spacing={2}>
      <Grid item>
        <Typography variant="h1" style={{ letterSpacing: "2px", fontWeight: "800" }}>
          Get in touch
        </Typography>
      </Grid>
      <Grid item xs={12} style={{paddingLeft: "15%", paddingRight: "15%", textAlign: 'center'}}>
        <Typography variant="h6" style={{opacity: "0.7"}}>
          For any queries enter your Phone Number and we will get back to you
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} md={8}>
          <TextField
            inputProps={{ maxLength: "10", style: {"background": "#4E4B66", borderRadius: "20px", color: "white", paddingBottom: "10px", borderColor: "transparent"} }}
            variant="outlined"
            value={number}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                setNumber(e.target.value);
              }
            }}
            placeholder="Phone number"
            fullWidth
            type="text"
            InputProps={{
              style: { borderRadius: "15px", borderColor: "transparent", paddingBottom: "10px", "background": "#4E4B66", root: {
                '& $notchedOutline': {
                  borderColor: 'transparent', // Change this to the desired color
                },
                '&:hover $notchedOutline': {
                  borderColor: 'transparent', // Change this to the desired hover color
                },
                '&$focused $notchedOutline': {
                  borderColor: 'transparent', // Change this to the desired focused color
                },
              }, },
              classes: {
                root: classes.textField,
                notchedOutline: classes.textField,
              }
            }}
          ></TextField>
        </Grid>
        <Grid item md={4} xs={12} style={{textAlign: 'center'}}>
          {loading ? (
              <CircularProgress color="primary"></CircularProgress>
            ) : (
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  background: "#A996FF",
                  color: "#4700AB",
                  fontSize: "14px",
                  marginTop: isMobile ? '10px' : null,
                  padding: isMobile ? "10px" : null
                }}
                onClick={() => submitPhone(number)}
                endIcon={<ArrowForward />}
              >
                Sign Up
              </Button>
            )}
        </Grid>
      </Grid>
      {/* {!isMobile ? null : (
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
          {loading ? (
            <CircularProgress color="primary"></CircularProgress>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitPhone(number)}
            >
              Connect
            </Button>
          )}
        </Grid>
      )} */}
    </Grid>
  );
}

export default ContactInput;
