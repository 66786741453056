import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Typography,
  withWidth,
} from "@material-ui/core"
import { ArrowForward, Shop } from "@material-ui/icons"
import paperplaneLogo from "./../../assets/images/Logos/Group 36933.png"
import { useLocation, useNavigate } from "react-router"
import { Menu } from "@material-ui/icons"
import React, { useState } from "react"
import { colors } from "../../colors/index"
import { Link, NavLink } from "react-router-dom"
import mixpanel from "mixpanel-browser"
const useStyles = makeStyles((theme) => ({
  parentGrid: {
    filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))",
    background: "#29263B",
    // position: "fixed",
    top: "3rem",
    zIndex: "999",
    width: "80%",
    borderRadius: "30px",

    [theme.breakpoints.down("sm")]: {
      // position: "fixed",
      padding: "1%",
      zIndex: "999",
      top: "3.5rem",
      width: "85%"
      // backgroundColor: "#FFF",
    },

    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  doctorDetails: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  headerImage: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}))
const Tag = ({ style }) => {
  return (
    <Typography
      style={{
        backgroundColor: "red",
        position: "absolute",
        padding: "0 0.5rem",
        borderRadius: "18px",
        right: "-1rem",
        top: "-1rem",
        fontSize: "11px",
        color: "white",
        width: "fit-content",
        ...style,
      }}
    >
      New
    </Typography>
  )
}
function NavBar({ data, width, isMobile = false }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const classes = useStyles()
  const navBarArray = [
    {
      label: "Home",
      route: "/",
      style: { color: colors.green, cursor: "pointer" },
      to: "/",
      onClick: () => {
        navigate("/")
        window.scrollTo(0, 0)
      },
    },
    // {
    //   label: "Buy Now Pay Later",
    //   route: "/bnpl",
    //   tag: true,
    //   style: { color: colors.green, cursor: "pointer" },
    //   to: "/",
    //   onClick: () => {
    //     // mixpanel.track("BNPL from navbar clicked")
    //     // window.open("https://www.youtube.com/channel/UCCIs3vb1mC15PrdPQBwgdjQ")
    //     navigate("/bnpl")
    //     window.scrollTo(0, 0)
    //   },
    // },
    // {
    //   label: "Videos",
    //   route: "/videos",
    //   // tag: true,
    //   style: { color: colors.green, cursor: "pointer" },
    //   to: "/",
    //   onClick: () => {
    //     mixpanel.track("Videos from navbar clicked")
    //     window.open("https://www.youtube.com/channel/UCCIs3vb1mC15PrdPQBwgdjQ")
    //   },
    // },
    // {
    //   label: "Video",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/");
    //     window.scrollTo(0, 0);
    //   },
    // },
    // {
    //   label: "Features",
    //   route: "/features",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/");
    //     window.scrollTo(0, 0);
    //   },
    // },
    //  {
    //   label: "Pricing",
    //  route: "/pricing",
    //   to: "/",
    
    //   style: { color: colors.green, cursor: "pointer" },
    //     onClick: () => {
    //       navigate("/pricing")
    //     window.scrollTo(0, 0)
    //   },
    //  },
    // {
    //   label: "Blogs",
    //   route: "/blogs",
    //   to: "/",

    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/blogs")
    //     window.scrollTo(0, 0)
    //   },
    //   tag: false,
    // },
    // {
    //   label: "ABHA",
    //   route: "/abdm",
    //   to: "/",

    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     navigate("/abdm")
    //     window.scrollTo(0, 0)
    //   },
    //   tag: true,
    // },

    {
      label: "About Us",
      route: "/vision",
      style: { color: colors.green, cursor: "pointer" },
      onClick: () => {
        navigate("/vision")
        window.scrollTo(0, 0)
      },
    },
    // {
    //   label: "Contact Us",
    //   route: "/contact",
    //   style: { color: colors.green, cursor: "pointer" },
    //   onClick: () => {
    //     // navigate("/");
    //     // window.scrollTo(1000, 2600);
    //   //   const targetPosition = document.body.scrollHeight * 0.8;

    //   // // Scroll to the target position
    //   //   window.scrollTo({
    //   //     top: targetPosition,
    //   //     behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
    //   //   });
    //     let objDiv = document.getElementById("contact-us")
    //     if (objDiv) {
    //       objDiv.scrollIntoView({ behavior: "instant", block: 'start' })
    //     }
    //   },
    // },
    {
      label: "Privacy Policy",
      route: "/privacyPolicy",
      style: { color: colors.green, cursor: "pointer" },
      onClick: () => {
        navigate("/privacyPolicy");
        window.scrollTo(0, 0);
      },
    },
  ]
  return (
    <Grid container alignItems="center" justifyContent="center" style={{paddingTop: "3.5rem"}}>
      <Grid
        item
        container
        className={classes.parentGrid}
        style={{
          flexWrap: "nowrap",
          // justifyContent: "space-between",
        }}
      >
        <Grid
          item
          container
          md={4}
          style={{
            cursor: "pointer",
            flexWrap: "nowrap",
            verticalAlign: "middle",
          }}
          onClick={() => navigate("/")}
        >
          <img
            alt="Clinic Logo"
            src={paperplaneLogo}
            style={{
              objectFit: "contain",
              width: isMobile ? "130px" : "165px",
            }}
          ></img>
        </Grid>
        <Hidden smDown>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            style={{
              flexWrap: "nowrap",
              marginLeft: "-10%",
              whiteSpace: "nowrap",
              // padding: "0 3.5rem",
            }}
          >
            <Grid container justifyContent="space-evenly" item xs={9}>
              {navBarArray.map((ele, idx) => (
                <Grid item key={idx} style={{ position: "relative", marginLeft: "auto" }}>
                  <NavLink
                    to={ele.route}
                    onClick={() => {
                      if (ele.onClick) ele?.onClick()
                      // window?.scrollTo(0, 0)
                    }}
                    style={({ isActive }) => ({
                      fontFamily: "Manrope",
                      color: "white",
                      textDecoration: "none",
                      fontSize: "18px",
                      textDecorationLine: "none",
                      fontWeight: isActive ? "1000" : "300",
                      marginLeft: 10
                    })}
                  >
                    {ele.label}
                  </NavLink>
                  {ele.tag ? <Tag /> : null}
                </Grid>
              ))}
            </Grid>
            <Grid item container justifyContent="end" style={{justifyContent: "end"}} xs={3}>
              {/* <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    mixpanel.track("Download App Clicked")

                    window.open(
                      "https://play.google.com/store/apps/details?id=paperplane.health.app&hl=en_GB&gl=IN"
                    )
                  }}
                  style={{ fontSize: "14px", padding: "0.2rem 1rem" }}
                >
                  Download App
                </Button>
              </Grid> */}
              {/* <Grid item>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    mixpanel.track("Login Clicked")

                    window.open("https://app.paperplane.health/auth/login")
                  }}
                  style={{ fontSize: "14px", padding: "5% 50%" }}
                >
                  Login
                </Button> 
                <IconButton style={{background: "#979797", marginRight: "5px"}}>
                  <ArrowForward style={{color: "white"}}/>
                </IconButton>
              </Grid> */}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item>
            <IconButton size="large" onClick={() => setMenuOpen(true)}>
              {" "}
              <Menu style={{color: "white"}}/>
            </IconButton>
            <Drawer
              anchor="bottom"
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <div
                style={{
                  width: "auto",
                  anchor: "bottom",
                  backgroundColor: "#262338",
                }}
              >
                <List onClick={() => setMenuOpen(false)}>
                  {[...navBarArray].map((ele, index) => (
                    <ListItem button key={ele.label}>
                      <ListItemText style={{ position: "relative" }}>
                        <NavLink
                          to={ele.route}
                          onClick={() => window?.scrollTo(0, 0)}
                          style={({ isActive }) => ({
                            fontFamily: "Manrope",
                            textDecoration: "none",
                            fontSize: "18px",
                            display: "block",
                            color: isActive ? colors.green : "white",

                            textDecorationLine: "none",
                            fontWeight: isActive ? "900" : "500",
                          })}
                        >
                          {ele.label}
                        </NavLink>
                        {/* {ele.tag ? (
                          <Tag style={{ left: "3.2rem", top: "-0 0.1rem" }} />
                        ) : null} */}
                      </ListItemText>
                    </ListItem>
                  ))}
                  {/* <ListItem>
                    <ListItemText
                      onClick={() => {
                        mixpanel.track("Login Clicked")

                        window.open("https://app.paperplane.health/auth/login")
                      }}
                      primary="Login"
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Manrope",
                          textDecoration: "none",
                          fontSize: "18px",
                          textDecorationLine: "none",
                          color: "#656565",
                          fontWeight: "500",
                        },
                      }}
                    ></ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      onClick={() => {
                        mixpanel.track("Download App Clicked")

                        window.open(
                          "https://play.google.com/store/apps/details?id=paperplane.health.app&hl=en_GB&gl=IN"
                        )
                      }}
                      primary="Download App"
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Manrope",
                          textDecoration: "none",
                          fontSize: "18px",
                          textDecorationLine: "none",
                          color: "#656565",
                          fontWeight: "500",
                        },
                      }}
                    ></ListItemText>
                  </ListItem> */}
                </List>
              </div>
            </Drawer>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default withWidth()(NavBar)
